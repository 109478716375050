import * as React from "react";
import { Link } from "gatsby";
import { get } from "lodash";
import { Container, Segment, Icon, Label, Button, Grid, Comment } from "semantic-ui-react";
import { MarkdownRemark, ImageSharp, MarkdownRemarkConnection, Site } from "../graphql-types";
import AuthorBar from "../components/AuthorBar";
import Card from "../components/Card";
import Header from "../components/Header";
import HeaderMenu from "../components/HeaderMenu";
import TagFooter from "../components/TagFooter";
import Section from "../components/Section";
import BlogTitle from "../components/BlogTitle";
import { DiscussionEmbed } from "disqus-react";
import {withLayout, LayoutProps, menuItems} from "../components/Layout";
import { graphql } from "gatsby";

interface BlogPostProps extends LayoutProps {
  data: {
    post: MarkdownRemark;
    recents: MarkdownRemarkConnection;
    site: Site
  };
}

const BlogPostPage = (props: BlogPostProps) => {
  const { frontmatter, html, timeToRead } = props.data.post;
  const avatar = frontmatter.author.avatar.children[0] as ImageSharp;

  const recents = props.data.recents.edges
    .map(({ node }) => {
      const recentAvatar = node.frontmatter.author.avatar.children[0] as ImageSharp;
      const recentCover = get(node, "frontmatter.image.children.0.fixed", {});

      return (
        <Card
          image={recentCover}
          title={node.frontmatter.title}
          description={{
            excerpt: node.excerpt,
            slug: node.fields.slug,
            timeToRead: node.timeToRead,
            updatedDate: node.frontmatter.updatedDate,
          }}
          author={{
            avatar: recentAvatar,
            meta: frontmatter.author,
          }}
        />
      );
    });

  const cover = get(frontmatter, "image.children.0.fixed", {} );
  return (
    <React.Fragment>
      <HeaderMenu pathname={props.location.pathname} items={menuItems} />
      <Header title={frontmatter.title} strapline={frontmatter.strapline} />
      <AuthorBar
        author={{ avatar, meta: frontmatter.author }}
        readTime={`${frontmatter.updatedDate} - ${timeToRead} min read`}
      />
      {cover.src &&
        <section className="u-width-100">
          <img className="u-width-100" {...cover} />
        </section>
      }
      <Section title={""} index={0} html={html} />
      <TagFooter tags={{ slugPrefix: "", tags: props.data.post.frontmatter.tags }} />
      <section className="l-blog-recents">
        {recents}
      </section>
    </React.Fragment>
  );
};

export default withLayout(BlogPostPage);

export const pageQuery = graphql`
  query TemplateBlogPost($slug: String!) {
  post: markdownRemark(fields: {slug: {eq: $slug}}) {
    html
    excerpt
    timeToRead
    fields {
      slug
    }
    frontmatter {
      tags
      author {
        id
        bio
        twitter
        avatar {
          children {
            ... on ImageSharp {
              fixed(width: 80, height: 80, quality: 100) {
                src
                srcSet
              }
            }
          }
        }
      }
      title
      strapline
      updatedDate(formatString: "MMM D, YYYY")
      image {
        children {
          ... on ImageSharp {
            fixed(width: 900, height: 300, quality: 100) {
              src
              srcSet
            }
          }
        }
      }
    }
  }
  recents: allMarkdownRemark(
    filter: {
      fields: {slug: {ne: $slug}}
      frontmatter: {draft: {ne: true}},
      fileAbsolutePath: {regex: "/blog/"},
    },
    sort: {order: DESC, fields: [frontmatter___updatedDate]},
    limit: 4
  ) {
    edges {
      node {
        fields {
          slug
        }
        timeToRead
        excerpt
        frontmatter {
          title
          strapline
          updatedDate(formatString: "DD MMMM, YYYY")
          image {
            children {
              ... on ImageSharp {
                fixed(width: 300, height: 100) {
                  src
                  srcSet
                }
              }
            }
          }
          author {
            id
            avatar {
              children {
                ... on ImageSharp {
                  fixed(width: 36, height: 36) {
                    src
                    srcSet
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
`;
