import { Link } from "gatsby";
import React from "react";
import { AuthorJson, ImageSharp } from "../../graphql-types";
import { Bem } from "../../utils/bem";
import "./styles.scss";

interface Props {
  author: {
    meta: AuthorJson,
    avatar: ImageSharp,
  };
  readTime?: string;
}

const component = new Bem("c-author-bar");
const descriptionComponent = new Bem("c-author-bar-description");

const AuthorBar = ({ author, readTime }: Props) => {
  return (
    <section className={component.with()}>
      <div className={component.with("avatar")}>
        <img {...author.avatar.fixed} />
      </div>
      <div className={component.with("description")}>
        <p className={descriptionComponent.with("author")}>{ author.meta.id }</p>
        <p className={descriptionComponent.with("bio")}>{ author.meta.bio }</p>
        <p className={descriptionComponent.with("read-time")}>{ readTime }</p>
      </div>
    </section>
  );
};

export default AuthorBar;
